import React, { useState } from "react";
import { LinkSection, linkSections } from "./data";
import { Icon } from "../../../../Core/Icon/Icon";
import { Text } from "../../../../Core/Text/Text";
import { Unpacked } from "@clearabee/ui-library-base";
import { useLinkContext } from "../../LinkContext";
import { styles } from "../styles";
import { Link } from "../../../../Core/Link/Link";

interface SectionProps {
  section: LinkSection;
}

interface SectionLinkProps {
  item: Unpacked<LinkSection["links"]>;
}

const SectionLink = ({ item }: SectionLinkProps): React.ReactElement => {
  const { LinkWrap } = useLinkContext();

  return (
    <li style={styles.sectionLink} key={item.title}>
      <LinkWrap link={item.link}>
        <Link color="dark" styles={styles.footerLink}>
          {item.title}
        </Link>
      </LinkWrap>
    </li>
  );
};

const Section = ({ section }: SectionProps): React.ReactElement => {
  // Function to split the links into two halves
  const splitLinksIntoTwoRows = (links: { title: string; link: string }[]) => {
    const halfway = Math.ceil(links.length / 2);
    return [links.slice(0, halfway), links.slice(halfway)];
  };

  // Check if it's the "core services" and split links accordingly
  if (section.heading.toLowerCase() === "core services") {
    const [firstHalfLinks, secondHalfLinks] = splitLinksIntoTwoRows(
      section.links,
    );
    return (
      <div className="px-4 mb-4 lg:mb-0">
        <div style={styles.textBold}>{section.heading}</div>
        <div className="hidden" style={{ display: "flex", columnGap: 32 }}>
          {/* First column of links */}
          <ul style={styles.sectionLinks} className="flex-1">
            {firstHalfLinks.map((item) => (
              <SectionLink item={item} key={item.title} />
            ))}
          </ul>
          {/* Second column of links */}
          <ul style={styles.sectionLinks} className="flex-1">
            {secondHalfLinks.map((item) => (
              <SectionLink item={item} key={item.title} />
            ))}
          </ul>
        </div>
      </div>
    );
  }

  // Default rendering for other sections
  return (
    <>
      <div style={styles.textBold}>{section.heading}</div>
      <ul style={styles.sectionLinks}>
        {section.links.map((item) => (
          <SectionLink item={item} key={item.title} />
        ))}
      </ul>
    </>
  );
};

const MobileSection = ({ section }: SectionProps): React.ReactElement => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div
        css={styles.mobileSectionContainer}
        onClick={() => setVisible(!visible)}
      >
        <Text styles={styles.textBold} fontSize="base">
          {section.heading}{" "}
        </Text>

        <Icon.Chevron
          size="small"
          color="dark"
          styles={[
            styles.openIcon,
            {
              transform: visible
                ? "translateY(-50%) rotate(-90deg)"
                : "rotate(90deg)",
            },
          ]}
        />
      </div>

      <ul style={styles.mobileSectionLink}>
        {visible &&
          section.links.map((item) => (
            <SectionLink item={item} key={item.title} />
          ))}
      </ul>
    </>
  );
};

export const PrimaryLinks = (): React.ReactElement => {
  return (
    <div css={styles.primaryLinksContainer}>
      {linkSections.map((section) => (
        <div style={styles.primaryLinks} key={section.heading}>
          <div css={styles.initiallyHidden}>
            <Section section={section} />
          </div>
          <div css={styles.initiallyShown}>
            <MobileSection section={section} />
          </div>
        </div>
      ))}
    </div>
  );
};

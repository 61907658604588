"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.users = void 0;
exports.users = {
    common: {
        title: "Users",
        addUser: "Add user",
    },
    errors: {
        noUserAttributes: "No user attributes...",
        updateAttributes: "Could not update attributes",
        fieldsMissing: "Field(s) requires attention to continue",
    },
    table: {
        headings: {
            status: "Status",
            name: "Name",
            email: "Email",
            defaultCompany: "Default Company",
            roles: "Roles",
            actions: "Actions",
        },
        actions: {
            edit: "Edit",
            delete: "Delete",
        },
        noResults: "No results found",
    },
    user: {
        noData: "No Data",
        form: {
            labels: {
                firstName: "First Name",
                lastName: "Last Name",
                emailAddress: "Email Address",
                phoneNumber: "Phone Number",
                address: "Address",
                companies: "Companies",
                defaultCompany: "Default Company",
                roles: "Roles",
                backToUsers: "Back to users",
                createAnother: "Create another user",
                isSupplier: "Are you creating a Supplier User?",
                supplier: "Supplier",
            },
            placeholders: {
                firstName: "First Name",
                lastName: "Last Name",
                emailAddress: "Email Address",
                phoneNumber: "Phone Number",
                address: "Address",
                companies: "Companies",
                field: "Search field..",
                operator: "Search operator..",
                searchValue: "Search company..",
                searchCompaniesValue: "Search companies..",
                searchDefaultCompanyValue: "Search company..",
                selectRoles: "Select roles..",
                selectRole: "Select role..",
                searchSupplier: "Search supplier..",
            },
            optionLabels: {
                contains: "Contains (Full-text search)",
                equals: "Equals (=)",
                firstName: "First Name",
                lastName: "Last Name",
                email: "Email",
                company: "Company",
            },
        },
        buttons: {
            updateAttributes: "Update Attributes",
            backToUsers: "Back to users",
            backToSubcontractors: "Back to subcontractors",
            create: "Create user",
            updateUser: "Update user",
            passwordResetLink: "Send password reset link",
            temporaryPasswordReset: "Reset temporary password",
            deleteUser: "Delete user",
            loading: "Loading...",
            update: "Update",
            loadTransactions: "Load Transactions",
            loadJobs: "Load Jobs",
            submit: "Submit",
            resetForm: "Reset form",
            addAnother: "Add another",
            yes: "Yes",
            no: "No",
            retry: "Retry",
            remove: "Remove",
            duplicate: "Duplicate",
        },
        titles: {
            newUser: "New user",
            manageUser: "Manage User",
            updateUser: "Update User",
            userAttributes: "User Attributes",
            beeLoyalTransactions: "Bee Loyal Transactions",
            userJobs: "User Jobs",
        },
        modals: {
            headings: {
                createUserSuccess: "User successfully created!",
                updateUserSuccess: "User successfully updated!",
                subcontractorUpdated: "Subcontractor successfully updated!",
                doYouWantToCreateUser: "Are you sure you want to create user(s)?",
            },
        },
        toasts: {
            sendPasswordResetLinkSuccess: "Password reset link sent successfully",
            sendTemporaryPasswordResetLinkSuccess: "Temporary password sent successfully",
            cannotUpdateUser: "User could not be updated. Try again",
            cannotUpdateAttributes: "Attributes could not be updated. Try again",
            cannotSendTemporaryPassword: "Unable to reset temporary password",
            cannotDeleteUser: "Could not delete user. Try again",
        },
    },
    subcontractors: {
        createNew: "Add subcontractor",
        headings: {
            subcontractors: "Subcontractors",
            updateSubcontractor: "Update Subcontractor",
            createSubcontractor: "Create A New Subcontractor",
            createSubcontractorSuccess: "Subcontractor successfully created!",
        },
        labels: {
            phone: "Phone",
            wc_license: "Waste Carriers Licence",
            email: "Email",
            postcode: "Postcode",
            username: "Username",
            password: "Password",
            company: "Company",
            status: "Status",
            onStop: "On Stop",
            enabled: "Enabled",
            disabled: "Disabled",
            date: "Date",
            jobReference: "Job Reference",
        },
        placeholders: {
            date: "Single date or a range...",
            jobReference: "Job Reference",
            status: "Select a Status",
            postcode: "Postcode",
        },
        messages: {
            noDescription: "No description provided",
            noCompanyName: "No company name",
            noJobsFound: "No jobs found",
            currentlyUnassigned: "Currently unassigned",
            confirmUnassign: "Please confirm that you want to unassign job:",
            noSubUsers: "This subcontractor has no users",
        },
        buttons: {
            showImages: "Show Images",
            showDescription: "Show Description",
            unassignJob: "Unassign Job",
            cancel: "Cancel",
            search: "Search",
            reset: "Reset",
            confirm: "Confirm",
            returnToSubcontractors: "Return to Subcontractors",
            viewSubcontractorVehicles: "View Subcontractor Vehicles",
            createSubcontractor: "Create Subcontractor",
        },
        errors: {
            couldNotGenerateCoordinates: "Coordinates could not be generated for this postcode",
            couldNotUnassign: "There was an problem when unassignning this job",
        },
        reviewVehicles: "Review Vehicles",
        review: "Review",
        approveVehicle: "Approve",
        rejectVehicle: "Disapprove",
        delete: "Delete",
        resetPassword: "Reset Password",
        manage: "Manage",
        edit: "Edit",
    },
    filters: {
        approveVehiclesHeading: "Search vehicles",
        approveVehiclesSearch: "Type here to search vehicles...",
        subcontractorJobsHeading: "Search jobs",
        subcontractorJobsSearch: "Type here to search jobs...",
    },
};

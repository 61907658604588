interface Link {
  title: string;
  link: string;
}

export interface LinkSection {
  heading: string;
  links: Link[];
}

export const linkSections: LinkSection[] = [
  {
    heading: "Core services",
    links: [
      {
        title: "Man & Van",
        link: "/rubbish-clearance",
      },
      {
        title: "Skip Hire",
        link: "/skip-hire",
      },
      {
        title: "Skip Bags",
        link: "/skip-bags",
      },
      {
        title: "Bin Collection",
        link: "/private-bin-collection",
      },
      {
        title: "Sofa Removal",
        link: "/sofa-removal",
      },
      {
        title: "Furniture Removal",
        link: "/furniture-removal",
      },
      {
        title: "Appliance Removal",
        link: "/appliance-removal",
      },
      {
        title: "House Clearance",
        link: "/house-clearance",
      },
      {
        title: "Needles and Sharps Removal",
        link: "/needles-and-sharps",
      },
      {
        title: "Commercial Skip Hire",
        link: "/skip-hire/commercial",
      },
      {
        title: "Business Waste",
        link: "/business-waste",
      },
    ],
  },
  {
    heading: "Where we operate",
    links: [
      {
        title: "Nationwide",
        link: "/locations",
      },
      {
        title: "London",
        link: "/locations/london-rubbish-removal",
      },
      {
        title: "Birmingham",
        link: "/locations/birmingham-rubbish-removal",
      },
      {
        title: "Bristol",
        link: "/locations/bristol-rubbish-removal",
      },
      {
        title: "Leeds",
        link: "/locations/leeds-rubbish-removal",
      },
    ],
  },
  {
    heading: "Useful links",
    links: [
      {
        title: "About us",
        link: "/about",
      },
      {
        title: "News",
        link: "/news",
      },
      {
        title: "Reviews",
        link: "/reviews",
      },
      {
        title: "Contact us",
        link: "/contact",
      },
    ],
  },
];
